import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const Container = styled.div`
  padding-top: 100px;
`
const Row = styled.div`
  display: flex;
  margin: 2rem auto;
  width: 70%;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (min-width: 980px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`
const Row2 = styled.div`
  display: flex;
  margin: 2rem auto;
  width: 70%;
  flex-direction: column-reverse;
  gap: 1rem;

  @media only screen and (min-width: 980px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const TextDiv = styled.div`
  width: 100%;

  h2 {
    font-size: 1rem !important;
  }
`

const ImageDivCrookedRight = styled.div`
  width: 100%;
  transform: rotate(1deg);
  border: 4px solid white;
  box-shadow: 0 0 1px black;
`

const ImageDivCrookedLeft = styled.div`
  width: 100%;
  transform: rotate(-1deg);
  border: 4px solid white;
  box-shadow: 0 0 1px black;
`

const ImageDivOnTopLeft = styled.div`
  width: 100%;
  transform: translateY(5%) translateX(-10%) rotate(-1deg);
  z-index: 2;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
  border: 4px solid white;

  @media only screen and (min-width: 980px) {
    transform: translateX(15%) rotate(-1deg);
  }
`

const ImageDivOnTopRight = styled.div`
  width: 100%;
  transform: translateY(10%) translateX(10%) rotate(1deg);
  z-index: 2;
  box-shadow: -10px -5px 5px rgba(0, 0, 0, 0.5);
  border: 4px solid white;

  @media only screen and (min-width: 980px) {
    transform: translateX(-15%) rotate(1deg);
  }
`

const Butik = () => (
  <Layout
    seo="Cykelbutik i Åre"
    meta="I vår butik belägen mitt i Åre säljer
  vi allt du kan tänkas behöva till din cykling"
  >
    <Container>
      <Row>
        <TextDiv>
          <h1>Komplett cykelbutik i Åre</h1>
          <h2>
            I vår butik belägen precis vid Åre Bergbana, mittemot Broken, säljer
            vi allt du kan tänkas behöva till din cykling.
          </h2>
          <p>
            Vi säljer cyklar från Lapierre, Transition och Rocky Mountain samt
            barncyklar från Kanadensiska Spawn. Vi säljer skydd från 100%,
            Leatt, Troy Lee samt hjälmar från Leatt, Bell, Troy Lee och Giro. Vi
            har komponenter från Shimano, Sram, Magura, OneUp, Renthal,
            Crankbrothers mfl.
          </p>
        </TextDiv>
        <ImageDivOnTopLeft>
          <StaticImage
            src="../images/page-images/bike-republic-butik.jpg"
            alt="Butik - Bike Republic"
          />
        </ImageDivOnTopLeft>
        <ImageDivCrookedRight>
          <StaticImage
            src="../images/page-images/bike-republic-butik-vinkel.jpg"
            alt="Butik - Bike Republic"
          />
        </ImageDivCrookedRight>
      </Row>
      <Row2>
        <ImageDivCrookedLeft>
          <StaticImage
            src="../images/page-images/bike-republic-butik-cyklar.jpg"
            alt="Butik - Bike Republic"
          />
        </ImageDivCrookedLeft>
        <ImageDivOnTopRight>
          <StaticImage
            src="../images/page-images/bike-republic-butik-ut.jpg"
            alt="Butik - Bike Republic"
          />
        </ImageDivOnTopRight>
        <TextDiv>
          <p>
            Vi är dessutom enda återförsäljare i Sverige av Title, startat av
            freeride legenden Brett Rheeder. Vi har som enda butik kläder från
            Dharco, det Australiska klädmärket som används av flertalet
            världscupteam. Lägg därtill däck från Maxxis, Michelin och Pirelli,
            skor från Crankbrothers och FiveTen, grepp och sadlar från Ergon så
            är butiken fullmatad med allt du kan tänkas behöva.
          </p>
        </TextDiv>
      </Row2>
    </Container>
  </Layout>
)

export default Butik
